import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BlogSectionStyled from './BlogSectionStyled';
import { BaseComponentProps } from '@/types';
import helpers from '@/utils/helpers';
import Reveal from '@/components/reveal/Reveal';

const query = graphql`
  query LatestBlogPost {
    allContentfulBlogPost(sort: { order: DESC, fields: createdAt }, limit: 2) {
      nodes {
        id
        slug
        title
        excerpt {
          id
          excerpt
        }
        createdAt
        image {
          gatsbyImageData
        }
        category
      }
      group(field: category) {
        field
        fieldValue
      }
    }
  }
`;

const BlogSection: React.FC<BaseComponentProps> = () => {
  const { allContentfulBlogPost } = useStaticQuery(query);
  return (
    <BlogSectionStyled>
      <div className="blog-container container">
        <Reveal className="section-header">
          <h2 className="mini-header">Latest Notes</h2>
        </Reveal>
        <div className="posts">
          {allContentfulBlogPost.nodes.map((_post) => (
            <Reveal className="post" key={_post.slug}>
              <Link
                to={`/notes/${_post.slug}`}
                className="link post-article"
                aria-label={`Read more on ${_post.title}`}
              >
                <div className="image-container">
                  {!!_post.image && (
                    <GatsbyImage
                      image={getImage(_post.image?.gatsbyImageData)}
                      alt={_post.image?.title}
                    />
                  )}
                </div>

                <div className="post-details">
                  <h3 className="title">{_post.title}</h3>

                  <div className="meta-info">
                    {!!_post.category?.[0] && (
                      <p className="category">{_post.category[0]}</p>
                    )}
                    <span className="separator" />
                    <p className="date">
                      {helpers.contentfulDate(_post.createdAt)}
                    </p>
                  </div>
                </div>
              </Link>
            </Reveal>
          ))}
        </div>
      </div>
    </BlogSectionStyled>
  );
};

export default BlogSection;
